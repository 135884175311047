<template>
  <div class="fixed" ref="fixed">
  <h1><a href="/"><span class="lemmi_logo">LEM<em>MI</em><span class="version">16s</span></span></a><span class="lemmi_category">Benchmarking Taxonomic Profilers for 16s Amplicons</span><span class="catchphrase">A catalog of evaluated tools | last update <i>2023/09/13</i></span></h1>
  <div id="nav">
    <router-link to="/">Benchmarks</router-link>
    <a target="_blank" href="https://www.ezlab.org/lemmi16s-documentation.html">Documentation<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" x="0px" y="0px" viewBox="0 0 100 100" width="12" height="12" class="icon outbound"><path fill="currentColor" d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"></path> <polygon fill="currentColor" points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9"></polygon></svg></a>
    <router-link to="/submission">Submission</router-link>
    <router-link to="/tools">All Evaluated Tools</router-link>
    <a target="_blank" href="https://gitlab.com/ezlab/lemmi16s/-/tree/v1.0.1">Standalone Pipeline<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" x="0px" y="0px" viewBox="0 0 100 100" width="12" height="12" class="icon outbound"><path fill="currentColor" d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"></path> <polygon fill="currentColor" points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9"></polygon></svg></a>
    <a target="_blank" href="https://zenodo.org/communities/lembench/search?q=lemmi16s">Compatible Ressources<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" x="0px" y="0px" viewBox="0 0 100 100" width="12" height="12" class="icon outbound"><path fill="currentColor" d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"></path> <polygon fill="currentColor" points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9"></polygon></svg></a>
    <router-link to="/about">About</router-link>
  </div>
  </div>
  <div id="spacer_menu" v-bind:style="{ height: navHeight }"></div>
  <router-view/>
</template>
<script>
export default {
    name: 'App',
    data () {
      return {
        navHeight: "0px"
      }
    },
    mounted() {
      this.navHeight = this.$refs.fixed.clientHeight+"px";
    },

    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'LEMMI16s -  benchmarking methods for 16s amplicon sequencing profiling';
            }
        },
    }
};
</script>
<style>
html {
  border:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}

#nav {
  padding:0;
  margin:0;
  clear:both;
}

#nav a {
  font-weight: bold;
  color: #d84315;
  text-decoration:none;
  padding: 10px;
  border-radius: 4px;
  display:inline-block;
}

#nav a.router-link-exact-active {
  color: white;
  background: #d84315;
  text-decoration:none;
}

h1 {
  font-variant: small-caps;
}

a {
  color: #d84315;
  text-decoration:none;
}

.lemmi_logo {
  float:left;
	margin: 0.1em 0.5em 0 0.5em;
	font-family: Arial, sans-serif;
	font-size: 1.5em;
	font-weight: bold;
	font-style: italic;
	color: #b20;
	text-shadow: 0.1em 0.1em 0.1em #bbb;
	text-decoration: none;
	text-rendering: optimizeLegibility;
  z-index:1000;
}

.lemmi_logo em {
	color: #936;
}

.lemmi_logo .version {
	color: #444;
  font-size: 0.5em;
  padding:0 0 0 0.25em;
}

.lemmi_category {
  font-family: "Arial", sans-serif;
  font-style: italic;
  float:left;
  top:0.7em;
  position:relative;
  font-size: 0.5em;
  color:#222;
  border-radius:2em;
  z-index:0;
  font-weight:600;
  text-shadow: 0.1em 0.1em 0.1em #bbb;
  width:50%;
  text-align:left;
}

.catchphrase {
  font-family: "Arial", sans-serif;
  font-style: italic;
  float:left;
  top:1.1em;
  position:relative;
  font-size: 0.4em;
  color:#222;
  border-radius:2em;
  z-index:0;
  font-weight:100;
  font-variant: none;
  width:50%;
  text-align:left;
  border-bottom: 2px solid #02d188;
  border-radius: 0;
  padding-bottom: 4px;
}

.fixed {
  position:fixed;
  top:0;
  background-color:white;
  width:100%;
  z-index:2000;
}

i {
  color: #d84315;
}




</style>
