<template>
    <div class="floating_block is_archive" :class="box_content.is_archive, this.display_archive">
    <h2>{{ niceTitle }}</h2>
    <div style="position: relative; width: 0; height: 0">
    <span class="is_new" :class="box_content.is_new">New</span>
    <span class="is_updated" :class="box_content.is_new">Updated</span>
    <span class="lemmi_version">{{ box_content.lemmi_version }}</span>
    </div>
    <table style="white-space: pre-line;">
        <tr><th id="firstline" colspan="2">Simulated reads from mock communities</th></tr>
        <tr><th>The target organisms are </th><td :class="box_content.target">{{ box_content.target }}</td></tr>
        <tr><th>The type of sample is </th><td :class="box_content.scope">{{ box_content.scope }}</td></tr>
        <tr><th>The sequencing tech. is </th><td :class="box_content.reads">{{ box_content.reads }}</td></tr>
        <tr><th>The reference sequences/taxonomy used is </th><td :class="box_content.ref">{{ box_content.ref }}</td></tr>
        <tr><th>Ref. release date: </th><td>{{ box_content.reference_details }}</td></tr>
        <tr><th>Unknown taxa are simulated: </th><td :class="box_content.unknown">{{ box_content.unknown }}</td></tr>
        <tr><th>Hardware: </th><td >{{ box_content.hardware }}</td></tr>
      </table>
    <div class="instance_summary_description">
    <div class="paragraph">
    <b>{{ box_content.description_homepage }}</b>
    </div>
    </div>
    <div><router-link :to="{name: 'InstanceDetails', params: {instance: instance}}" class="explore" v-bind:class="comparisonState[instance]">>>&nbsp;See details</router-link></div>
    <div><router-link :to="{name: 'InstanceComparison', params: {comparison_instance: comparison_instance[0], instance: instance}}" class="compare" v-bind:class="comparisonState[instance]">>>&nbsp;See details side-by-side with<br/>this instance as the right column</router-link></div>
    <div class="select_compare" v-bind:class="comparisonState[instance]"><input @change="setCompare(instance)" type="checkbox" v-bind:checked="is_checked[instance]" />Enable side-by-side details display<br/> and use this instance as the left column</div>
    </div>
</template>

<script>
import niceTitles from "@/../public/data/nice_titles.json";

export default {
  name: 'InstanceSummary',
  props: {
    instance: String,
    is_checked: Object,
    comparisonState: Object,
    structure: Object,
    niceTitles: Object,
    comparison_instance: Object,
    display_archive: String
  },
  data() {
    return {
      box_content: {
        "text": ""
      },
      niceTitle: niceTitles[this.instance] ? niceTitles[this.instance] : this.instance,
    }
  },
  mounted() {
      fetch(`data/${this.instance}.description.json`)
        .then(res => res.json())
        .then(data => this.box_content = data)
        .catch(error => this.box_content = {'text1': 'No description file can be found.'});
  },
  methods: {
    setCompare(current_instance) {
      var unchecking = false
      if (this.$props.is_checked[current_instance]){
        unchecking = true
      }
      for (var an_instance in this.$props.comparisonState) {
          if (unchecking){
              this.$props.comparisonState[an_instance] = 'no_comparison'
              this.$props.is_checked[an_instance] = false;
              this.$props.comparison_instance[0] = 'none';
          }
          else if (current_instance == an_instance){
              this.$props.comparisonState[an_instance] = 'to_compare_with'
              this.$props.is_checked[an_instance] = true;
              this.$props.comparison_instance[0] = an_instance;
          }
          else {
              this.$props.comparisonState[an_instance] = 'compare_with'
              this.$props.is_checked[an_instance] = false;
              this.$props.comparison_instance[0] = current_instance;
          }
      }
      //this.$props.comparisonState[sample] = !this.isComparison[sample];
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  display: block;
  font-size:1.3em;
  padding:0.3em;
  margin-bottom:1em;
  color:black;
  background-color:white;
  border-radius:1em;
}

.floating_block {
  width:16em;
  float:left;
  border: 2px solid white;
  box-shadow: 0 0 15px #e1fef5;
  border-radius: 1em;
  padding: 1em;
  margin: 1em;
  background-color: #daf1e7;
  height: 31em;
}

.floating_block h2 {
  height: 2.6em;
  font-size: 1.1em;
  border: 1px solid black;
}

.floating_block td {
  font-size: 0.7em;
  font-weight: 500;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0;
  padding:0.1em 0.15em;
  min-width:6.6em;
}

.NCBI {
  background-color: #02d188;
  color:white;
}

.GTDB {
  background-color: #d84315;
  color:white;
}

.yes {
  background-color: #02d188;
  color:black;
}

.date {
  background-color: #02d188;
  color:white;
}

.no {
  background-color: #d84315;
  color:white;
}

.excluded {
  background-color: #d84315;
  color:white;
}

.included {
  background-color: #02d188;
  color:white;
}


.Illumina {
  background-color: #02d188;
  color:black;
}

.ONT {
  background-color: #d84315;
  color:white;
}

.SILVA {
  background-color: #02d188;
  color:black;
}

.clinical {
  background-color: #02d188;
  color:black;
}

.mock {
  background-color: #d84315;
  color:white;
}

.bacteria {
  background-color: #02d188;
  color:black;
}

.viruses {
  background-color: #111;
  color:white;
}

.eukaryotes {
  background-color: #d84315;
  color:white;
}

.floating_block th {
  font-size: 0.7em;
  font-weight: 500;
  text-align: right;
  padding: 0.2em 0.5em;
  margin: 0;
  border: 0;
}

.floating_block th#firstline {
  font-size: 0.7em;
  font-weight: 500;
  text-align: center;
  padding: 0.2em 0.5em;
  margin: 0;
  border: 0;
  background-color:black;
  color:white;
}

.floating_block tr {
  padding: 0;
  margin: 0;
  background-color: #FFF;
}

.floating_block table {
    border-spacing: 0px;
    border-collapse: collapse;
    margin-top: 0.2em;
    margin-bottom: 1em;
    width: 100%;
    border: 1px solid black;
}

.instance_summary_description {
  margin:0;
  padding:1em;
  width:14em;
  background-color: #F8FDFB;
  text-align:left;
  border:0.3em solid #02d188;
  border-radius: 1em;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  height: 4em;
}

.explore.no_comparison {
  display:block;
}

.explore.to_compare_with {
  display:none;
}

.explore.compare_with {
  display:none;
}

.explore {
  font-variant: small-caps;
  font-weight: bold;
  margin-top:1em;
  background-color:white;
  border-radius:1em;
}

.compare.no_comparison {
  display:none;
}

.compare.to_compare_with {
  display:none;
}

.compare.compare_with {
  display:block;
}

.compare {
  font-variant: small-caps;
  font-weight: bold;
  margin-top:1em;
  background-color:white;
  border-radius:1em;
  font-size: 0.8em;
}

.select_compare.compare_with {
  display:none;
}

.select_compare {
  display:block;
  margin-top:1em;
  background-color:none;
  border-radius:1em;
  font-size: 0.8em;
}

.paragraph {
  font-size:0.8em;
  margin-bottom:0.9em;
  text-align:left;
}

.is_new {
  display:none;
}

.is_updated {
  display:none;
}

.is_new.yes {
  display:block;
  position:absolute;
  top: -7.5em;
  left: -2em;
  color:black;
  background-color:gold;
  width:3em;
  border-radius:1em;
  border:0.2em solid white;
  font-weight: 700;
  padding:0.1em;
}

.is_archive.yes {
  background-color:#EEE;
  color:#000;
}

.is_archive.yes.Display {
  display:none;
}

.lemmi_version {
  display:block;
  position:absolute;
  top: -7.5em;
  left: 6.5em;
  color:#CCC;
  background-color:#FFF;
  width:3em;
  border-radius:1em;
  border:0.2em solid white;
  font-weight: 700;
  padding:0.1em 0.3em;
}

.is_updated.updated {
  display:block;
  position:absolute;
  top: -7.5em;
  left: -2em;
  color:white;
  background-color:goldenrod;
  width:5em;
  border-radius:1em;
  border:0.2em solid white;
  font-weight: 500;
  padding:0.1em;
}

</style>
